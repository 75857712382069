body {
    background-color: #f7f7f7;
}

a {
    text-decoration: none;
}

.app {
    max-width: 1170px;
    min-height: 100vh;
    background-color: #fff;
    margin: 0 auto;
}

.blog {
    box-sizing: border-box;
    max-width: 770px;
    width: 100%;
    padding: 80px 10px 10px;
    margin: 0 auto;
}

.header {
    line-height: 1.8rem;
}

.head-top {
    text-align: center;
    margin-bottom: 1.5rem;
}

.head-top .name {
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
}

.head-top .name a {
    display: inline-block;
    background-color: #f03838;
    color: #fff;
    -webkit-transition: padding 0.3s ease-out;
    transition: padding 0.3s ease-out;
}

.head-top .name a:hover {
    padding: 0 0.8rem;
}

.head-top p {
    color: #9e9e9e;
    font-size: 1.1rem;
    margin: 1rem 0 0
}

.head-bot {
    text-align: center;
}

.head-bot ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.6em;
}

.head-bot ul li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

}

.head-bot ul li a {
    color: #f03838;
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 0 1rem;
}

.footer {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
    color: #666;
}

.footer p {
    color: #9e9e9e;
    font-size: 1rem;
    margin: 0.5rem;
}

.footer p a {
    color: #f03838;
}