.about-title {
    position: relative;
    color: #212121;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    padding: 0 2rem;
}

.article-top-meta {
    position: relative;
    color: #9e9e9e;
    font-size: 1.3rem;
    text-align: center;
    margin: 1rem 0;
}

.article-top-meta span {
    margin: 0 0.5rem;
}

.article-top-meta a {
    color: #9e9e9e;
}

.article-top-meta a:hover {
    color: #f03838;
}

.article-top-meta a time {
    font-style: italic;
}


.article-content {
    margin: 2rem 0;
    font-size: 1.2rem;
    color: #424242;
}



.color {
    color: #f03838;
}

.list li {
    margin-top: 1rem;
}