.article-name {
    margin-top: 1.5rem;
}


.article-name .archive {
    padding: 0;
    margin: 2rem auto;
}

.archive-post {
    text-align: left;
    margin: 2em auto;
}

.archive-post .archive-title {
    font-size: 1.8em;
    line-height: 1;
    position: relative;
    color: #212121;
    margin: 0.5rem 0;
}

.archive-post .archive-title a {
    color: #000;
}

.archive-post .archive-title a span {
    padding-bottom: 3px;
    -webkit-transition: box-shadow 0.15s;
    transition: box-shadow 0.15s;
    box-shadow: inset 0 -2px 0 rgba(240, 56, 56, 0);
}

.archive-post .archive-title a:hover {
    color: #212121;
}

.archive-post .archive-title a:hover span {
    box-shadow: inset 0 -2px 0 rgba(240, 56, 56, 0.4);
}

.archive-post time {
    font-style: italic;
}

.archive-post time a {
    font-size: 1.2rem;
    color: #9e9e9e;
}

.archive-post time a:before {
    content: '#';
}

.archive-post time a:hover {
    color: #f03838;
}