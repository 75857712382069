.detail-title {
    position: relative;
    color: #212121;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    padding: 0 2rem;
}

.all {
    margin: 4rem auto;
}

h1 {
    text-align: center;
}

h2 {
    margin: 2.5rem 0;

}

h3 {
    margin: 2rem 0;
}

p,
li {
    font-size: 1.2rem;
    color: #424242;
}

code {
    border-left: 1px solid #fff;
    color: #666;
}