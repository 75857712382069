.home-title {
    position: relative;
    color: #212121;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    padding: 0 2rem;
}

.pagination {
    position: relative;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    /* 居中按钮 */
    margin-top: 1rem;
    gap: 10px;
    /* 增加按钮之间的间距 */
}

.pagination .pagination-prev,
.pagination .pagination-next {
    color: #f03838;
    font-size: 1.2rem;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    height: 2.8rem;
    padding: 0 1.4rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.4s, color 0.4s;
    cursor: pointer;
}

.pagination .pagination-prev:hover,
.pagination .pagination-next:hover {
    color: #fff;
    background-color: #f03838;
    border-color: #f03838;
}

.loading {
    text-align: center;
}

.err {
    color: red;
}